import { createApp } from 'vue'
import App from './App.vue'
import { setupStore } from './store'
import { setupAntd } from './plugin/antd'
import {setupRouter} from './router'
import "./app.less"
// @ts-ignore
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

const app = createApp(App)

function setupPlugins () {
    setupAntd(app)
}

async function setupApp() {
    // 挂载vuex状态管理
    setupStore(app);
    // Multilingual configuration
    // Asynchronous case: language files may be obtained from the server side
    // await setupI18n(app);
    // // 挂载路由
    await setupRouter(app);

    app.use(vue3videoPlay);


    app.mount('#app');
}

setupPlugins();

setupApp();
