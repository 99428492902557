import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import type {App} from "vue";
import {Storage} from "@/utils/Storage";
import {ACCESS_TOKEN_KEY, ACCESS_USER_INFO} from "@/enums/cacheEnum";
import operateRoutes from "@/router/operate";

const routes: Array<RouteRecordRaw> = [
  
    {
        path: "/loginCus",
        name: "loginCus",
        meta: {
            title: "客户登陆",
        },
        component: () =>
            import(/* webpackChunkName: "loginCus" */ "../views/loginCus/index.vue"),
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "管理员登陆",
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
    },
    {
        path: "/",
        component: () =>
            import(/* webpackChunkName: "layout" */ "../views/layout.vue"),
        children: [
            {
                path: "/",
                redirect: "/home",
            },
            ...operateRoutes,
            {
                path: "/home",
                name: "home",
                meta: {
                    title: "首页",
                },
                component: () =>
                    import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
            },

            {
                path: "/user",
                name: "user",
                meta: {
                    title: "用户列表",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ "../views/user/list/index.vue"
                        ),
            },
            {
                path: "/auth",
                name: "auth",
                meta: {
                    title: "角色管理",
                },
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/auth/index.vue"),
            },
            // 报错 暂时注释
            {
                path: "/music",
                name: "music",
                meta: {
                    title: "视频音乐",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "withInfo" */ "../views/operate/music/index.vue"
                        ),
            },
            {
                path: "/cash",
                name: "cash",
                meta: {
                    title: "用户提现",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ "../views/user/cash/index.vue"
                        ),
            },
            {
                path: "/examine",
                name: "examine",
                meta: {
                    title: "待审核用户",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ "../views/user/examine/index.vue"
                        ),
            },
            {
                path: "/adminUser",
                name: "adminUser",
                meta: {
                    title: "管理员列表",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "adminUser" */ "../views/admin/user/index.vue"
                        ),
            },
            {
                path: "/role",
                name: "role",
                meta: {
                    title: "角色列表",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "role" */ "../views/admin/role/index.vue"
                        ),
            },
            // {
            //   path: "/video",
            //   component: () =>
            //     import(/* webpackChunkName: "admin" */ "../views/video/layout.vue"),
            //   children: [
            //     {
            //       path: "/video",
            //       redirect: "/video_list",
            //     },
            //
            //   ],
            // },
            {
                path: "/video",
                name: "video",
                meta: {
                    title: "视频管理",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "video_list" */ "../views/video/index.vue"
                        ),
            },
            {
                path: "/cate",
                name: "cate",
                meta: {
                    title: "视频分类",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "video_list" */ "../views/video/cate.vue"
                        ),
            },
            {
                path: "/order",
                name: "order",
                meta: {
                    title: "订单管理",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ "../views/order/list/index.vue"
                        ),
            },
            {
                path: "/divide",
                name: "divide",
                meta: {
                    title: "分成设置",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ "../views/order/divide/index.vue"
                        ),
            },
            {
                path: "/collect",
                name: "collect",
                meta: {
                    title: "统计管理",
                },
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/collect/index.vue"),
            },
        ],
    },
    {
        path: "/share",
        name: "share",
        meta: {
            title: "直播间分享",
        },
        component: () =>
            import(
                /* webpackChunkName: "share" */ "../views/operate/share/index.vue"
                ),
    },
    {
        path: "/shareVideo",
        name: "shareVideo",
        meta: {
            title: "视频分享",
        },
        component: () =>
            import(
                /* webpackChunkName: "share" */ "../views/operate/share/shareVideo.vue"
                ),
    },
    {
        path: "/room",
        name: "room",
        meta: {
            title: "直播中台",
        },
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/room/index.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export async function setupRouter(app: App) {
    // 创建路由守卫
    // createRouterGuards(router, whiteNameList);

    router.beforeEach((to, from, next) => {
        const store = Storage.get(ACCESS_TOKEN_KEY);
        if (to.meta.title) {
            (<unknown>document.title) = to.meta.title;
        }
        if (
            !store &&
            to.path !== "/loginCus" &&
            to.path !== "/login" &&
            to.path !== "/share" &&
            to.path !== "/shareVideo"
        ) {
            next({path: localStorage.getItem("GO_TO_LOGIN") || 'loginCus'});
            localStorage.removeItem("GO_TO_LOGIN")
        } else {
            next();
        }
    });

    app.use(router);

    // 路由准备就绪后挂载APP实例
    await router.isReady();
}

export default router;
