/** 用户token */
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';


export const ACCESS_USER_INFO = 'USER_INFO';

export const ACCESS_USER_AUTH= 'USER_AUTH';

/** 监控机器人数据 **/
export const ROOM_BOOT_DATA = "ROOM_BOOT_DATA"

/** 国际化 */
export const LOCALE_KEY = 'LOCALE__';

/** 主题色 */
export const THEME_KEY = 'THEME__';

/** 用户信息 */
export const USER_INFO_KEY = 'USER__INFO__';

// role info key
export const ROLES_KEY = 'ROLES__KEY__';
/** 是否锁屏 */
export const IS_LOCKSCREEN = 'IS_LOCKSCREEN';
/** 标签页 */
export const TABS_ROUTES = 'TABS_ROUTES';
