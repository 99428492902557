<template>
  <config-provider :locale="zhCN">
    <router-view #="{ Component }">
      <component :is="Component" />
    </router-view>
  </config-provider>
</template>

<script lang="ts" setup>
  import {onMounted} from "vue";
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
  onMounted(() => {
    console.log(process.env.VUE_APP_BASE_API)
    localStorage.removeItem("UNIFIED_CONTROL_ERROR_MODAL")
    localStorage.removeItem("COMFIRM_TIMEOUT")
  })

	// const debounce = (callback: (...args: any[]) => void, delay: number) => {
	// 	let tid: any;
	// 	return function(...args: any[]) {
	// 		const ctx = self;
	// 		tid && clearTimeout(tid);
	// 		tid = setTimeout(() => {
	// 			callback.apply(ctx, args);
	// 		}, delay);
	// 	}
	// }

	// const _ = (window as any).ResizeObserver;
	// (window as any).ResizeObserver = class ResizeObserver extends _ {
	// 	constructor(callback: (...args: any[]) => void) {
	// 		callback = debounce(callback, 20);
	// 		super(callback);
	// 	}
	// }

</script>

<style lang="less">
@import "@/assets/css/common.less";
@import "@/assets/css/iconfont/iconfont.css";
@import "@/assets/css/antdv-common.less";
input,
textarea {
  outline: none;
  border: none;
  resize: none;
}
</style>
