import { RouteRecordRaw } from "vue-router";

const operateRoutes: Array<RouteRecordRaw> = [
  {
    path: "/opera",
    component: () =>
      import(/* webpackChunkName: "opera" */ "../views/operate/layout.vue"),
    children: [
      {
        path: "/qrcode",
        name: "qrcode",
        meta: {
          title: "二维码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/qrcode/index.vue"
          ),
      },
      {
        path: "/sign",
        name: "sign",
        meta: {
          title: "签到",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/sign/index.vue"
          ),
      },
      {
        path: "/videoManage",
        name: "videoManage",
        meta: {
          title: "视频管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/video/index.vue"
          ),
      },
      {
        path: "/image",
        name: "image",
        meta: {
          title: "开屏暖场图",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/image/index.vue"
          ),
      },
      {
        path: "/question",
        name: "question",
        meta: {
          title: "调查问卷",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/questionnaire/index.vue"
          ),
      },
      {
        path: "/notice",
        name: "notice",
        meta: {
          title: "预告",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/notice/index.vue"
          ),
      },
      {
        path: "/remind",
        name: "remind",
        meta: {
          title: "开播提醒",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/remind/index.vue"
          ),
      },
      {
        path: "/h5",
        name: "h5",
        meta: {
          title: "宣传页",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/h5/index.vue"
          ),
      },
      {
        path: "/sensitive",
        name: "sensitive",
        meta: {
          title: "敏感词设置",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/sensitive/index.vue"
          ),
      },
      {
        path: "/draw",
        name: "draw",
        meta: {
          title: "抽奖",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/draw/index.vue"
          ),
      },
      {
        path: "/award",
        name: "award",
        meta: {
          title: "创建答题",
        },
        component: () => import("../views/operate/award/index.vue"),
        // children: [
        //   {
        //     path: "/award",
        //     redirect: "/createAward",
        //   },
        //   {
        //     path: "/createAward",
        //     name: "createAward",
        //     meta: {
        //       title: "创建答题",
        //     },
        //     component: () => import("../views/operate/award/index.vue"),
        //   },
        //   {
        //     path: "/subject",
        //     name: "subject",
        //     meta: {
        //       title: "创建题目",
        //     },
        //     component: () => import("../views/operate/award/subject.vue"),
        //   },
        // ],
      },
      {
        path: "/red",
        name: "red",
        meta: {
          title: "红包",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/red/index.vue"
          ),
      },
      {
        path: "/vote",
        name: "vote",
        meta: {
          title: "投票管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/vote/index.vue"
          ),
      },
      {
        path: "/advert",
        name: "advert",
        meta: {
          title: "滚动广告",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/advert/index.vue"
          ),
      },
      {
        path: "/invite",
        name: "invite",
        meta: {
          title: "邀约大赛",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/invite/index.vue"
          ),
      },
      {
        path: "/blacklist",
        name: "blacklist",
        meta: {
          title: "黑名单",
        },
        component: () =>
          import(
            /* webpackChunkName: "qrcode" */ "../views/operate/blacklist/index.vue"
          ),
      },
      {
        path: "/robot",
        component: () => import("../views/operate/robot/layout.vue"),
        children: [
          {
            path: "/reboot",
            redirect: "/robot",
          },
          {
            path: "/robot",
            name: "robot",
            meta: {
              title: "机器人互动",
            },
            component: () =>
              import(
                /* webpackChunkName: "addBoot" */ "../views/operate/robot/robot.vue"
              ),
          },
          {
            path: "/speak",
            name: "speak",
            meta: {
              title: "发言",
            },
            component: () =>
              import(
                /* webpackChunkName: "bootSubject" */ "../views/operate/robot/speak.vue"
              ),
          },
        ],
      },
      {
        path: "/withInfo",
        name: "withInfo",
        meta: {
          title: "提现记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "withInfo" */ "../views/statis/withinfo.vue"
          ),
      },
      {
        path: "/flowing",
        name: "flowing",
        meta: {
          title: "资金流水",
        },
        component: () =>
          import(
            /* webpackChunkName: "withInfo" */ "../views/statis/flowing.vue"
          ),
      },

    ],
  },
];

export default operateRoutes;
