import {
    Modal,
    Table,
    Menu,
    Input,
    Form,
    Card,
    Checkbox,
    Radio,
    Col,
    Row,
    Select,
    DatePicker,
    Button,
    Dropdown,
    Switch,
    Pagination,
    Tag,
    Divider,
    Space,
    Breadcrumb,
    Avatar,
    Upload,
    Tabs,
    Collapse,
    Tooltip,
		InputNumber,
		Image
} from 'ant-design-vue';
import type { App } from 'vue';

// import { AButton } from '@/components/basic/button/index';

// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.variable.min.css';
import 'dayjs/locale/zh-cn';

export function setupAntd(app: App<Element>) {
    // app.component('AButton', AButton);

    app
        .use(Form)
        .use(Tooltip)
        .use(Collapse)
        .use(Tabs)
        .use(Dropdown)
        .use(Avatar)
        .use(Input)
        .use(Upload)
        .use(Modal)
        .use(Breadcrumb)
        .use(Table)
        .use(Menu)
        .use(Pagination)
        .use(Pagination)
        .use(Card)
        .use(Tag)
        .use(Switch)
        .use(Space)
        .use(Divider)
        .use(Checkbox)
        .use(Radio)
        .use(Col)
        .use(Row)
        .use(Select)
        .use(Button)
				.use(InputNumber)
				.use(Image)
        .use(DatePicker);
}
